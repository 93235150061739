<template>
  <Generic :item="item">
    <template #icon>
      <!-- left area containing the icon -->
    </template>
    <template #content>
      <!-- main area containing the title, subtitle, ... -->
        <div>{{ state }}</div>
        <input id="input" type="text" />
        <button v-on:click="onSubmit"> Set </button>
    </template>
    <template #indicator>
      <!-- top right area, empty by default -->
    </template>
  </Generic>
</template>

<script>
import Generic from "./Generic.vue";

export default {
  name: "HAVideo",
  props: {
    item: {
      name: "Kyle",
    },
  },
  data: () => ({
      state: null
  }),
  created() {
      console.log(this.item.baseUrl)
      this.fetchData()
  },
  methods: {
    onSubmit: async function(ev) {
      const input = document.getElementById("input")
      const video = input.value
      await fetch(`${this.item.baseUrl}/api/states/sensor.stickpc_video`, {
        method: 'POST',
        body: JSON.stringify({state: video}),
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.item.token}`
        }
      })
      this.state = video
      
    },
      fetchData: async function () {
          const result = await fetch(`${this.item.baseUrl}/api/states/sensor.stickpc_video`, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${this.item.token}`
              }
          })
          const json = await result.json()
          const state = json.state
          this.state = state
      }
  },
  components: {
    Generic,
  },
};
</script>
